import { Box, IconButton, styled, Text, Link } from '@attentive/picnic';
import {
  SHOPIFY_ATTRIBUTION_OPT_IN_DATE,
  ShopifyAttributionOptInStatesResult,
  ShopifyAttributionOptInStatus,
} from '@attentive/reporting-platform';

import React from 'react';

const UnreadIcon = styled('div', {
  width: '$size3',
  height: '$size3',
  borderRadius: '$radiusMax',
  backgroundColor: '$hyperlinkBlue700',
});

const AttributionStateMessages = {
  [ShopifyAttributionOptInStatus.ELIGIBLE]: {
    title: 'Upcoming Changes to Order Attribution',
    description: `Starting ${SHOPIFY_ATTRIBUTION_OPT_IN_DATE}, Attentive will directly pull Shopify order data for more accurate revenue attribution. You can opt-in early on the Home page.`,
    callToAction: 'Go to Home page',
    url: '/home',
    dismissable: false,
  },
  [ShopifyAttributionOptInStatus.REFRESHING]: {
    title: 'Your attribution changes are processing',
    description:
      'It may take up to 1 day for all historical reporting data to be updated with this attribution change. Only reporting data from 2023 onward will be included in this change.',
    callToAction: 'Learn more',
    url: 'https://help.attentivemobile.com/hc/en-us/articles/35631183378708--Beta-Switch-to-the-new-Shopify-revenue-attribution-model',
    dismissable: false,
  },
  [ShopifyAttributionOptInStatus.COMPLETED]: {
    title: 'Your attribution changes are now live!',
    description: 'You can now view the updated data reflecting the new settings.',
    callToAction: 'Go to Analytics dashboard',
    url: '/analytics/dashboard',
    dismissable: true,
  },
};

export const ShopifyAttributionRow = ({
  shopifyAttributionStates,
}: {
  shopifyAttributionStates?: ShopifyAttributionOptInStatesResult;
}) => {
  if (!shopifyAttributionStates) {
    return null;
  }

  const { status, isSuccessDismissed, setSuccessDismissed } = shopifyAttributionStates;

  if (!status) {
    return null;
  }

  const notEligible = status === ShopifyAttributionOptInStatus.INELIGIBLE;
  const isCompleted = status === ShopifyAttributionOptInStatus.COMPLETED;

  if (notEligible || (isCompleted && isSuccessDismissed)) {
    return null;
  }

  const { title, description, callToAction, url, dismissable } =
    AttributionStateMessages[status] || {};

  return (
    <Box css={{ py: '$space3', px: '$space4', display: 'flex', position: 'relative' }}>
      <Box css={{ flex: 1, pr: '$space1' }}>
        <Text
          variant="caption"
          css={{
            fontWeight: '$bold',
          }}
        >
          {title}
        </Text>
        <Text variant="caption" css={{ pt: '$space1' }}>
          {description}
        </Text>
        {callToAction && (
          <Text
            as={Link}
            variant="caption"
            href={url}
            css={{ pt: '$space1' }}
            data-nav-id="notifications-menu-cta"
          >
            {callToAction}
          </Text>
        )}
      </Box>
      <Box
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <UnreadIcon aria-label="Unread notification" />
      </Box>
      {dismissable && (
        <IconButton
          iconName="X"
          description="Dismiss notification"
          size="extraSmall"
          css={{ position: 'absolute', top: '$space3', right: '$space3' }}
          onClick={() => setSuccessDismissed(true)}
          data-nav-id="notifications-menu-dismiss"
        />
      )}
    </Box>
  );
};
