import React from 'react';
import { Box, Button, Grid, Heading, Link, Text } from '@attentive/picnic';

import featuredImageSrc from '../../assets/beyond-the-message-q22025.png';

const promoContent = {
  backgroundColor: '#F9F7F0',
  heading: 'Beyond the Message: Q2 Product Releases and Consumer Trends',
  body: `Join us for the next installment of Beyond the Message, where our product team will delve into our Q2 product releases and share key findings from our 2025 Consumer Trends Report.`,
  altText: 'Illustration of gears, a magic wand signifying AI, and a text bubble.',
  buttonText: 'Register Now',
  buttonLink:
    'https://event.attentive.com/btmq22025?utm_source=website&utm_medium=ui-sign-in-page&utm_campaign=webinar-4.25.2025-btm-q2-2025',
};

export const SignInLayout: React.FC = ({ children }) => {
  return (
    <Box css={{ backgroundColor: '$bgAccent' }}>
      <Grid
        columns={[1, 2, 2, 3, 3]}
        css={{
          $$signinLayout$contentTop: 'clamp(16px, 20vh, 256px)',
          backgroundColor: '$bgDefault',
          minHeight: '100vh',
          minWidth: '320px',
          overflowY: 'auto',
          maxWidth: '2200px',
          margin: '0 auto',
          boxShadow: '$shadow2',
        }}
      >
        <Grid.Cell colSpan={1} css={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            css={{
              flex: '1',
              display: 'flex',
              justifyContent: 'center',
              px: '$space8',
              paddingTop: '$$signinLayout$contentTop',
            }}
          >
            {children}
          </Box>
          <Box css={{ padding: '$space2' }}>
            <Text variant="micro">
              &copy; {new Date().getFullYear()} Attentive. See{' '}
              <Link
                href="https://attentivemobile.com/terms/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms
              </Link>{' '}
              and{' '}
              <Link
                href="https://attentivemobile.com/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy
              </Link>
              .
            </Text>
          </Box>
        </Grid.Cell>
        <Grid.Cell
          colSpan={[1, 1, 1, 2, 2]}
          css={{
            display: 'none',
            '@bp1': {
              justifyContent: 'center',
              display: 'grid',
              backgroundColor: promoContent.backgroundColor,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'none',
            },
          }}
        >
          <Box
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              css={{
                textAlign: 'center',
                flex: 0,
                mb: '$space10',
                paddingTop: '15vh',
                maxWidth: '800px',
                width: '100%',
              }}
            >
              <Box
                as="img"
                css={{ maxWidth: '8000px', width: '100%', mb: '$space10' }}
                src={featuredImageSrc}
                alt={promoContent.altText}
              />
              <Box
                css={{
                  textAlign: 'left',
                  maxWidth: '715px',
                  width: '100%',
                  padding: '0 $space8',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <Heading
                  variant="lg"
                  css={{
                    mb: '$space4',
                    fontSize: '$fontSize4',
                    '@bp3': { fontSize: '$fontSize5' },
                  }}
                >
                  {promoContent.heading}
                </Heading>
                <Text css={{ mb: '$space4' }}>{promoContent.body}</Text>
                <Button
                  as={Link}
                  href={promoContent.buttonLink}
                  target="_blank"
                  variant="basic"
                  css={{
                    backgroundColor: 'rgba(0,0,0,0)',
                    textDecoration: 'none',
                    '&:hover': {
                      color: '$grayscale0 !important',
                      backgroundColor: '$grayscale900 !important',
                      borderColor: '$grayscale900 !important',
                    },
                  }}
                >
                  {promoContent.buttonText}
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid.Cell>
      </Grid>
    </Box>
  );
};
